import { CourseCategoriesResource } from "../CourseCategoriesStore/Types";

export interface Waiting {
  kind: "waiting";
}

export interface Loading {
  kind: "loading";
}

export interface Ready {
  kind: "ready";
  courses: CoursesResource;
  mode: string;
}

export interface Sorting {
  kind: "sorting";
  courses: CoursesResource;
  mode: string;
  filters: SelectedFilter[];
}

export const waiting = (): Waiting => {
  return {
    kind: "waiting",
  };
};

export const loading = (): Loading => {
  return {
    kind: "loading",
  };
};

export const ready = (courses: CoursesResource, mode: string): Ready => {
  return {
    kind: "ready",
    courses,
    mode,
  };
};

export const sorting = (
  courses: CoursesResource,
  mode: string,
  filters: SelectedFilter[],
): Sorting => {
  return {
    kind: "sorting",
    courses,
    mode,
    filters,
  };
};

export type CoursesState = Waiting | Loading | Ready | Sorting;

export interface MiniCourseResource {
  id: number;
  name: string;
  slug: string;
}

export interface CourseResource {
  id: number;
  slug: string;
  name: string;
  preview_headshot_image_url: string;
  preview_masthead_image_url: string;
  preview_mini_description: string;
  certificate_landscape_url: string;
  masthead_image_url: string;
  content_version: string;
  brand_logo_url: string;
  paid: boolean;
  best_seller: boolean;
  went_live_in_the_past_month: boolean;
  new_course: boolean;
  sample: boolean;
  enrolled_student_present: boolean;
  rating: number;
  thought_leader_name: string;
  course_path: string;
  course_release_date: string;
  status_icon: string;
  enrolment_percent_completion?: string;
  enrolment_id: number;
  tile_color: string;
  tile_description: string;
  content_live: string;
  tile_video_url: string;
  tile_icon_color: string;
  embedded_host_url: string;
  categories: CourseCategoriesResource;
}

export interface CertificateResource {
  enrolment_id: number;
  name: string;
  slug: string;
  preview_image: string;
  passed: boolean;
}

export interface CourseReadingResource {
  id: number;
  name: string;
  rank: number;
  description: string;
  target_url: string;
  image_url: string;
}

export interface Filter {
  id: string;
  name: string;
  slug: string;
}

export interface SelectedFilter {
  label: string;
  id: string;
  value: string;
  name: string;
  checked: boolean;
}

export type Course = CourseResource;
export type Certificate = CertificateResource;
export type CertificatesResource = CertificateResource[];
export type CoursesResource = CourseResource[];
