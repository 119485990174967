import React, { Component } from "react";
import { observer } from "mobx-react";
import { Link } from "react-router-dom";
import { CourseResource } from "../../../../../stores/CoursesStore/Types";
import { CourseCategoryResource } from "../../../../../stores/CourseCategoriesStore/Types";
import PreviewImage from "./PreviewImage";
import StatusImage from "./StatusImage";
import Rating from "./Rating";
import Progress from "./Progress";

interface Props {
  courseResource: CourseResource;
  idx: number;
  useATag?: boolean;
  categories?: CourseCategoryResource[];
  increaseHeight?: boolean;
}

const CourseBody = ({ courseResource, categories }) => (
  <div className="copyWrap">
    <div className="bg">
      {courseResource.subscription_only && !courseResource.has_subscription && (
        <div id="overlay">
          <div id="text">
            <i className="lock icon"></i>

            <span>Subscriber Only</span>
          </div>
        </div>
      )}
      <div className="course-status">
        <StatusImage courseResource={courseResource} />
      </div>
      <div className="course-headshot">
        <PreviewImage courseResource={courseResource} />
        {courseResource.brand_logo_url && (
          <div className="course-logo">
            <img src={courseResource.brand_logo_url} alt="brand" />
          </div>
        )}
      </div>
    </div>
    <div className="main">
      <div className="title">{courseResource.name}</div>
      <p className="teaser">{courseResource.preview_mini_description}</p>
    </div>
    <div
      className={`course_footer ${
        courseResource.enrolled_student_present ? "enrolled-footer" : ""
      }`}
    >
      <div className="course-rating">
        <Rating courseResource={courseResource} />
      </div>
      <Progress courseResource={courseResource} />
    </div>
  </div>
);

class Course extends Component<Props, {}> {
  courseLink = () => {
    const { courseResource } = this.props;
    if (courseResource.embedded_host_url) {
      return courseResource.embedded_host_url;
    } else if (courseResource.enrolment_id) {
      return `/enrolments/${courseResource.enrolment_id}`;
    }

    return courseResource.course_path;
  };

  render() {
    const { courseResource, idx, useATag, increaseHeight, categories } =
      this.props;

    return (
      <div
        className="column animateme three"
        data-from={0.45 + 0.07 * idx}
        data-opacity="0"
        data-to={idx * 0.07}
        data-translatey="180"
        data-when="enter"
      >
        <div className="ui loader"></div>

        {useATag || courseResource.content_version == "upcoming" ? (
          <a className="content" href={this.courseLink()}>
            <CourseBody
              courseResource={courseResource}
              categories={categories}
            />
          </a>
        ) : (
          <Link className="content" to={this.courseLink()}>
            <CourseBody
              courseResource={courseResource}
              categories={categories}
            />
          </Link>
        )}
      </div>
    );
  }
}

export default observer(Course);
